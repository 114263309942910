import React, { useEffect, useState } from "react"
import buttons from "../../../images/Instrument-Buttons.png"
import joysticks from "../../../images/Instrument-Joysticks.png"
import touch from "../../../images/Instrument-Touch.png"
import BasicButton from "../../../assets/components/BasicButton"
import { VolumeUp, VolumeMute, VolumeDown, VolumeOff } from "@mui/icons-material"

import { Box, Slider } from "@mui/material"

import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen"
import { usePostMqttChangeVolume } from "../../../mqtt/mqttHandlerContext"
import { InstrumentType, SONG_TYPES, Song, Instrument } from "../../../utils/types"
import { Drawer } from "../Welcome"
import Text from "../../../assets/components/Text"
import InstrumentSettings from "./InstrumentSettings"
import { useConnectedInstruments } from "../../../hooks/contexts/connectedInstrumentsContext"
import { useCurrentUser } from "../../../hooks/contexts/currentUserContext"
import { buttonSounds, joystickSounds, touchSounds } from "../../../utils/api"
import AllSoundsAvailable from "./AllSoundsAvailable"
import Funkingen from "../../../assets/icons/funkingen_2.png"

// Returnerar korrekt bild beroende på vilket instrument id vi anropar med
function handleImageSource(type: InstrumentType): string {
    switch (type) {
        case "buttons":
            return buttons
        case "touch":
            return touch
        case "joysticks":
            return joysticks
        default:
            return buttons
    }
}

const VolumeIcon = ({ value }: { value: number }) => {
    if (value === 0) {
        return <VolumeOff />
    }
    if (value <= 0.3 && value > 0) {
        return <VolumeMute />
    }
    if (value < 0.3 && value >= 0.7) {
        return <VolumeDown />
    }
    if (value > 0.7) {
        return <VolumeUp />
    } else {
        return <VolumeDown />
    }
}

interface InstrumentsProps {
    currentSong: Song | undefined
    instrumentVolumes: { [key: string]: number }
    setInstrumentVolumes: React.Dispatch<React.SetStateAction<{ [key: string]: number }>>
    connectedInstruments: Instrument[]
}

const Instruments = ({ currentSong, instrumentVolumes, setInstrumentVolumes, connectedInstruments }: InstrumentsProps) => {
    const postMqttChangeVolume = usePostMqttChangeVolume()
    const isSmallScreen = useIsSmallScreen()
    const [isOpen, setIsOpen] = useState(false)
    const [isAllSongsDrawerOpen, setIsAllSongsDrawerOpen] = useState(false)
    const [selectedInstrument, setSelectedInstrument] = useState<Instrument>({ deviceId: "", type: "", serialNr: "", color: "" })
    const currentUser = useCurrentUser()
    const songList = currentUser?.songList
    const shouldShowSettings = currentSong?.type === SONG_TYPES.KEY

    const toggleDrawer = ({ instrumentId, instrumentType, instrumentSerialNr, color }: { instrumentId?: string; instrumentType?: InstrumentType; instrumentSerialNr?: string; color?: string }) => {
        setIsOpen(!isOpen)
        setSelectedInstrument({
            color: color ?? "",
            deviceId: instrumentId ?? "",
            type: instrumentType ?? "",
            serialNr: instrumentSerialNr ?? "",
        })
    }

    const handleSlider = (event: React.ChangeEvent<HTMLInputElement>, instrument: any) => {
        const newVolume = parseFloat(event.target.value)
        setInstrumentVolumes((prevVolumes: any) => ({
            ...prevVolumes,
            [instrument]: newVolume,
        }))
        postMqttChangeVolume(newVolume.toString(), instrument)
    }

    // Fetch initial volume values from storage when the component mounts
    useEffect(() => {
        const storedVolumesString = localStorage.getItem("instrumentVolumes")
        if (storedVolumesString) {
            const storedVolumes = JSON.parse(storedVolumesString)
            setInstrumentVolumes(storedVolumes)
        }
    }, [])

    // Save volume values to storage whenever they change
    useEffect(() => {
        localStorage.setItem("instrumentVolumes", JSON.stringify(instrumentVolumes))
    }, [instrumentVolumes])

    const toggleAllSongsDrawer = ({
        instrumentId,
        instrumentType,
        instrumentSerialNr,
        color,
    }: {
        instrumentId?: string
        instrumentType?: InstrumentType
        instrumentSerialNr?: string
        color?: string
    }) => {
        setIsAllSongsDrawerOpen(!isAllSongsDrawerOpen)
        setSelectedInstrument({
            color: color ?? "",
            deviceId: instrumentId ?? "",
            type: instrumentType ?? "",
            serialNr: instrumentSerialNr ?? "",
        })
    }

    return connectedInstruments && connectedInstruments.length > 0 ? (
        <Box
            sx={{
                display: "grid",
                gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr",
                gap: isSmallScreen ? "0rem" : "1rem",
                height: isSmallScreen ? "60%" : "100%",
                rowGap: isSmallScreen ? "2rem" : "0rem",
            }}
        >
            {connectedInstruments.map((instrument: Instrument) => (
                <Box key={instrument.deviceId} sx={{ display: "flex", width: "100%", height: isSmallScreen ? "7rem" : "11rem" }}>
                    <Box sx={{ display: "flex", width: isSmallScreen ? "70%" : "auto" }}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            {/* <VolumeUp fontSize="small" sx={{ mb: 1 }} /> */}
                            <VolumeIcon value={instrumentVolumes[instrument.deviceId]} />
                            <Slider
                                sx={{
                                    width: "5px",
                                    "& .MuiSlider-thumb": {
                                        color: "white",
                                        height: "5px",
                                        borderRadius: "2px",
                                    },
                                    "& .MuiSlider-track": {
                                        color: "#77e077",
                                    },
                                    "& .MuiSlider-rail": {
                                        color: "#acc4e4",
                                    },
                                    "& .MuiSlider-active": {
                                        color: "green",
                                    },
                                }}
                                aria-label="Slider"
                                orientation="vertical"
                                valueLabelDisplay="auto"
                                value={instrumentVolumes[instrument.deviceId] ?? 0.5}
                                min={0}
                                max={1}
                                step={0.1}
                                onChange={(event: any) => handleSlider(event, instrument.deviceId)}
                            />
                        </Box>

                        <Box sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                            <img src={handleImageSource(instrument.type)} alt="Bild på instrument" width={"100%"} style={{ position: "relative", zIndex: 1 }} />
                            <Box
                                sx={{
                                    backgroundColor: instrument.color ?? "white",
                                    position: "absolute",
                                    top: 15,
                                    left: 20,
                                    borderRadius: "50%",
                                    width: "10px",
                                    height: "10px",
                                    zIndex: 2,
                                    opacity: 0.5,
                                }}
                            ></Box>
                        </Box>
                    </Box>
                    {songList && (
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: isSmallScreen ? "30%" : "auto" }}>
                            <InstrumentInformation
                                instrument={instrument}
                                songList={songList}
                                shouldShowSettings={shouldShowSettings}
                                toggleDrawer={toggleDrawer}
                                toggleAllSongsDrawer={toggleAllSongsDrawer}
                                isSmallScreen={isSmallScreen}
                            />
                        </Box>
                    )}
                    <Drawer variant="bottom" isOpen={isOpen} toggleDrawer={() => toggleDrawer({})} heading="Välj ljud">
                        <InstrumentSettings currentSong={currentSong} selectedInstrument={selectedInstrument} />
                    </Drawer>

                    <Drawer variant="bottom" isOpen={isAllSongsDrawerOpen} toggleDrawer={() => toggleAllSongsDrawer({})} heading="Välj ljud">
                        <AllSoundsAvailable songList={songList} selectedInstrument={selectedInstrument} />
                    </Drawer>
                </Box>
            ))}
        </Box>
    ) : (
        <Box sx={{ height: "20%", display: "flex", alignItems: "center", justifyContent: isSmallScreen ? "left" : "center", paddingRight: isSmallScreen ? 0 : "10em" }}>
            <img src={Funkingen} alt="Funkingen" style={{ height: "100%" }} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Text italic size={"heading"} textAlign="center">
                    Väntar på Funki instrument...
                </Text>
            </Box>
        </Box>
    )
}

interface InstrumentInformationProps {
    instrument: Instrument
    songList: Song[] | undefined
    shouldShowSettings: boolean
    toggleDrawer: any
    toggleAllSongsDrawer: any
    isSmallScreen: boolean
}

const InstrumentInformation = ({ instrument, songList, shouldShowSettings, toggleDrawer, toggleAllSongsDrawer, isSmallScreen }: InstrumentInformationProps) => {
    const currentInstrumentSong = songList?.find((song: Song) => instrument.currentSong?.id === song.id)

    function getInstrumentSound() {
        if (instrument.currentSong?.sound) {
            if (instrument.type === "buttons") {
                return buttonSounds.find((sound) => sound.id === instrument.currentSong?.sound)
            }
            if (instrument.type === "joysticks") {
                return joystickSounds.find((sound) => sound.id === instrument.currentSong?.sound)
            }
            if (instrument.type === "touch") {
                return touchSounds.find((sound) => sound.id === instrument.currentSong?.sound)
            }
        }
        return null
    }

    const instrumentSound = getInstrumentSound()
    const soundName = instrumentSound?.name || "Unknown sound"

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                justifyContent: "left",
            }}
        >
            <Box sx={{ flexGrow: 1 }}>
                {currentInstrumentSong?.title && (
                    <Box>
                        <Text size={"tiny"} bold>
                            {currentInstrumentSong.type === SONG_TYPES.MUSIC ? "Musik" : currentInstrumentSong.type === SONG_TYPES.SOUNDBANKS ? "Ljudlandskap" : "Tonart"}
                        </Text>
                        <Text>{currentInstrumentSong.title}</Text>
                    </Box>
                )}
                {instrumentSound && (
                    <Box paddingTop="0.5rem">
                        <Text size={"tiny"} bold>
                            Ljud
                        </Text>
                        <Box sx={{ display: "flex", columnGap: "0.25rem", alignItems: "center" }}>
                            <BasicButton
                                variant="outlined"
                                title={soundName}
                                sx={{ padding: "2px" }}
                                onClick={() => toggleDrawer({ instrumentId: instrument.deviceId, instrumentType: instrument.type, instrumentSerialNr: instrument.serialNr })}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
            <BasicButton
                title="Välj ljud"
                variant="text"
                sx={{ padding: "2px", width: "2rem" }}
                onClick={() => toggleAllSongsDrawer({ instrumentId: instrument.deviceId, instrumentType: instrument.type, instrumentSerialNr: instrument.serialNr })}
            />
        </Box>
    )
}

export default Instruments
