import { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react"
import { Client } from "paho-mqtt"
import { MQTT_API } from "../utils/api"
import { useCurrentUser } from "../hooks/contexts/currentUserContext"
import { Instrument, InstrumentType, SONG_TYPES } from "../utils/types"
import {
    ConnectedInstrumentsContext,
    ConnectedInstrumentsProvider,
    useConnectedInstruments,
    useSetConnectedInstruments,
    useUpdateConnectedInstrument,
} from "../hooks/contexts/connectedInstrumentsContext"

interface MqttHandlerContextProps {
    mqttIsConnected: boolean
    postChangeSong: ({ message, deviceId, songType }: { message: string; deviceId?: string; songType?: SONG_TYPES }) => void
    postChangeVolume: (message: string, instrumentId: string) => void
    postChangeMasterVolume: (message: string) => void
    postResetNetwork: (instrumentId: string) => void
}

export interface ConnectedInstrumentInfo {
    type: InstrumentType
    serialNr: string
    lastOnlineTimestamp?: number
}

const MqttHandlerContext = createContext<MqttHandlerContextProps>({
    mqttIsConnected: false,
    postChangeSong: () => {},
    postChangeVolume: () => {},
    postChangeMasterVolume: () => {},
    postResetNetwork: () => {},
})

const generateRandomClientId = () => {
    const randomNumber = Math.floor(Math.random() * 90000) + 10000
    return randomNumber.toString()
}

export const MqttHandlerProvider = ({ children }: { children: ReactNode }) => {
    const currentUser = useCurrentUser()
    const [mqttClient, setMqttClient] = useState<Client | undefined>(undefined)
    const [mqttIsConnected, setMqttIsConnected] = useState<boolean>(false)
    const updateConnectedInstruments = useUpdateConnectedInstrument()
    const connectedInstruments = useConnectedInstruments()
    const setConnectedInstruments = useSetConnectedInstruments()
    const customerInstruments = currentUser?.customer?.instruments

    const options = {
        host: "333ab3d8a3304b908eb458b1479ed3b4.s1.eu.hivemq.cloud",
        port: 8884,
        protocol: "wss",
        clientId: "web" + generateRandomClientId(),
        userName: MQTT_API.CREDITS.USERNAME,
        password: MQTT_API.CREDITS.PASSWORD,
        keepAliveInterval: 30,
        cleanSession: true,
    }

    ////// USE EFFECTS //////

    useEffect(() => {
        if (!currentUser?.customer) {
            console.log("No customer connected, skipping MQTT handler setup")
            return
        } else {
            console.log("Customer found, initializing MQTT handler")

            // Create MQTT client
            const client = new Client(options.host, options.port, options.clientId)

            // Configure MQTT client
            configureMqttClient(client)

            // Set an interval to check if instruments have lost connection
            // const intervalId = configureIntervalCheckForInstruments()

            // Cleanup when component unmounts
            return () => {
                // Cleanup MQTT client
                if (client.isConnected()) {
                    // Update onConnectionLost to not reconnect
                    client.onConnectionLost = (error) => {
                        setMqttIsConnected(false)
                    }
                    // Disconnect MQTT client
                    client.disconnect()
                    console.log("Disconnected MQTT client on component unmount")
                }

                // Clear the interval
                // clearInterval(intervalId)
                console.log("Cleared interval for checking timed out instruments on component unmount")
            }
        }
    }, [currentUser])

    useEffect(() => {
        if (mqttClient) {
            console.log("mqttClient is connected", mqttClient.isConnected())
            setMqttIsConnected(mqttClient.isConnected())

            if (currentUser?.customer?.id) {
                // Subscribe to topics
                const customerId = currentUser?.customer?.id

                currentUser?.customer?.instruments?.forEach((instrument) => {
                    mqttClient.subscribe(`/${instrument.deviceId}/status`)
                })
            }
        } else {
            setMqttIsConnected(false)
        }
    }, [mqttClient])

    ////// PRIVATE METHODS //////

    const configureMqttClient = (client: Client) => {
        // Set on connection lost
        client.onConnectionLost = (error) => {
            console.error(`MQTT connection lost: ${error.errorMessage}`)
            setMqttIsConnected(false)
            setMqttClient(undefined)

            // Reconnect if necessary
            connectMqttClient(client)
        }

        // Connect the client
        connectMqttClient(client)

        // Set on message arrived
        client.onMessageArrived = (message: any) => {
            currentUser?.customer?.instruments.forEach((instrument: Instrument) => {
                const deviceIdStatus = `/${instrument.deviceId}/status`

                // Handle ONLINE status
                if (message.destinationName.includes(deviceIdStatus) && message.payloadString === "ONLINE") {
                    // Check if the instrument is already connected
                    const existingInstrument = connectedInstruments.find((inst) => inst.deviceId === instrument.deviceId)

                    if (existingInstrument) {
                        // Instrument is already connected, update if necessary
                        updateConnectedInstruments({
                            deviceId: existingInstrument.deviceId,
                            serialNr: existingInstrument.serialNr,
                            type: existingInstrument.type,
                            color: existingInstrument.color,
                            currentSong: {
                                ...existingInstrument.currentSong, // Preserve current song details
                            },
                        })
                    } else {
                        // If the instrument isn't connected, add it to the connected instruments
                        updateConnectedInstruments({
                            deviceId: instrument.deviceId,
                            serialNr: instrument.serialNr,
                            type: instrument.type,
                            color: instrument.color,
                            currentSong: {
                                id: undefined,
                                songType: undefined,
                                sound: undefined,
                                category: undefined,
                                key: undefined,
                            },
                        })
                    }
                }

                // Handle OFFLINE status
                if (message.destinationName.includes(deviceIdStatus) && message.payloadString === "OFFLINE") {
                    // Remove the instrument from the connected instruments array
                    updateConnectedInstruments(
                        {
                            deviceId: instrument.deviceId,
                            serialNr: instrument.serialNr,
                            type: instrument.type,
                            color: instrument.color,
                            currentSong: {
                                id: undefined,
                                songType: undefined,
                                sound: undefined,
                                category: undefined,
                                key: undefined,
                            },
                        },
                        true
                    ) // Pass 'true' to the 'remove' argument to trigger removal
                }
            })
        }

        // Mock instruments data
        // const mockConnectedInstruments: Instrument[] = [
        //     { deviceId: "joystick1", serialNr: "JOY-12345", type: "joysticks", currentSong: { id: undefined, songType: undefined, sound: undefined, category: undefined, key: undefined } },
        //     { deviceId: "buttons1", serialNr: "BUT-12345", type: "buttons", currentSong: { id: undefined, songType: undefined, sound: undefined, category: undefined, key: undefined } },
        //     { deviceId: "touch1", serialNr: "TOU-12345", type: "touch", currentSong: { id: undefined, songType: undefined, sound: undefined, category: undefined, key: undefined } },
        // ]

        // mockConnectedInstruments.forEach((instrument: Instrument) => {
        //     updateConnectedInstruments(instrument)
        // })
    }

    const connectMqttClient = (client: Client) => {
        client.connect({
            useSSL: true,
            onSuccess: () => {
                console.log("Successfully connected MQTT client")
                setMqttClient(client)
            },
            onFailure: (error) => {
                console.log("Connection failed:", error.errorMessage)
                // Handle what should happen if connection fail
            },
            userName: options.userName,
            password: options.password,
            keepAliveInterval: options.keepAliveInterval,
        })
    }

    ////// METHODS FOR POSTING ON TOPICS //////

    const postChangeSong = ({ message, deviceId }: { message: string; deviceId?: string }) => {
        const payLoad = {
            message: message,
            timeStamp: Date.now(),
        }
        // Retained message when sending to all instruments. Instruments powered on after arrangement is chosen will receive arrangement upon MQTT connection.
        if (mqttClient?.isConnected()) {
            if (deviceId) {
                mqttClient.send(`/${deviceId}/${MQTT_API.TOPIC_ACTION.SONG}`, JSON.stringify(payLoad), 1, false)
            } else {
                // Array.from(connectedInstruments, ([instrumentId]) => mqttClient.send(`/${instrumentId}/${MQTT_API.TOPIC_ACTION.SONG}`, JSON.stringify(payLoad), 1, true))
                customerInstruments?.forEach((instrument) => mqttClient.send(`/${instrument.deviceId}/${MQTT_API.TOPIC_ACTION.SONG}`, JSON.stringify(payLoad), 1, true))
            }
        } else {
            console.error("MQTT client not connected: unable to send change song message")
        }
    }

    const postChangeVolume = (message: string, deviceId?: string) => {
        if (mqttClient?.isConnected()) {
            mqttClient.send(`/${deviceId}/${MQTT_API.TOPIC_ACTION.VOLUME}`, message, 0, false)
        } else {
            console.error("MQTT client not connected: unable to send change volume message")
        }
    }

    const postChangeMasterVolume = (message: string) => {
        if (mqttClient?.isConnected() && connectedInstruments) {
            connectedInstruments.forEach((instrument) => {
                mqttClient.send(`/${instrument.deviceId}/${MQTT_API.TOPIC_ACTION.VOLUME}`, message, 0, false)
            })
        } else {
            console.error("MQTT client not connected: unable to send master volume message")
        }
    }

    const postResetNetwork = (deviceId: string) => {
        if (mqttClient?.isConnected()) {
            mqttClient.send(`/${deviceId}/${MQTT_API.TOPIC_ACTION.RESET_NETWORK}`, "reset", 0, false)
        } else {
            console.error("MQTT client not connected: unable to send reset network message")
        }
    }

    return (
        <MqttHandlerContext.Provider
            value={{
                mqttIsConnected,
                postChangeSong,
                postChangeVolume,
                postChangeMasterVolume,
                postResetNetwork,
            }}
        >
            {children}
        </MqttHandlerContext.Provider>
    )
}

export const useMqttIsConnected = () => useContext(MqttHandlerContext).mqttIsConnected
export const usePostMqttChangeSong = () => useContext(MqttHandlerContext).postChangeSong
export const usePostMqttChangeVolume = () => useContext(MqttHandlerContext).postChangeVolume
export const usePostMqttChangeMasterVolume = () => useContext(MqttHandlerContext).postChangeMasterVolume
export const usePostMqttResetNetwork = () => useContext(MqttHandlerContext).postResetNetwork
