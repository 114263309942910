import { createContext, useState, ReactNode, useContext, useEffect } from "react"
import { Instrument } from "../../utils/types"

interface ConnectedInstrumentsContextProps {
    connectedInstruments: Instrument[]
    setConnectedInstruments: (instruments: Instrument[]) => void
    updateConnectedInstruments: (instrument: Instrument, remove?: boolean) => void
}

export const ConnectedInstrumentsContext = createContext<ConnectedInstrumentsContextProps | undefined>(undefined)

export const ConnectedInstrumentsProvider = ({ children }: { children: ReactNode }) => {
    const [connectedInstruments, setConnectedInstruments] = useState<Instrument[]>([])

    const updateConnectedInstruments = (newInstrument: Instrument, remove: boolean = false) => {
        setConnectedInstruments((prevInstruments) => {
            const instrumentIndex = prevInstruments.findIndex((instrument) => instrument.deviceId === newInstrument.deviceId)

            if (remove) {
                return prevInstruments.filter((instrument) => instrument.deviceId !== newInstrument.deviceId)
            }

            if (instrumentIndex === -1) {
                return [...prevInstruments, newInstrument]
            }

            const updatedInstruments = [...prevInstruments]
            updatedInstruments[instrumentIndex] = {
                ...prevInstruments[instrumentIndex],
                currentSong: {
                    ...prevInstruments[instrumentIndex].currentSong,
                    ...newInstrument.currentSong,
                },
            }

            return updatedInstruments
        })
    }

    useEffect(() => {
        console.log("Current connected instruments: ", connectedInstruments)
    }, [connectedInstruments])

    return <ConnectedInstrumentsContext.Provider value={{ connectedInstruments, updateConnectedInstruments, setConnectedInstruments }}>{children}</ConnectedInstrumentsContext.Provider>
}

// Hook to access connected instruments
export const useConnectedInstruments = () => {
    const context = useContext(ConnectedInstrumentsContext)
    if (!context) {
        throw new Error("useConnectedInstruments must be used within a ConnectedInstrumentsProvider")
    }
    return context.connectedInstruments
}

// Hook to set connected instruments
export const useSetConnectedInstruments = () => {
    const context = useContext(ConnectedInstrumentsContext)
    if (!context) {
        throw new Error("useSetConnectedInstruments must be used within a ConnectedInstrumentsProvider")
    }
    return context.setConnectedInstruments
}

// Hook to update connected instruments one by one
export const useUpdateConnectedInstrument = () => {
    const context = useContext(ConnectedInstrumentsContext)
    if (!context) {
        throw new Error("useUpdateConectedInstrument must be used within a ConnectedInstrumentsProvider")
    }
    return context.updateConnectedInstruments
}
