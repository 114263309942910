import React from "react"
import { useState, useEffect } from "react"
import { Instrument, Song } from "../../../utils/types"
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { formatString } from "../../../utils/formatter"
import { useConnectedInstruments } from "../../../hooks/contexts/connectedInstrumentsContext"
import { useChangeCurrentSong } from "../../../hooks/contexts/currentSongContext"

const toggleButtonGroupStyling = {
    backgroundColor: "#f5f5f5",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexWrap: "wrap",
}

const toggleButtonStyling = {
    color: "#555",
    backgroundColor: "#fff",
    borderRadius: "5px",
    margin: "0 0px",
    textTransform: "none",
    fontSize: "14px",
    fontWeight: "500",
    padding: "16px 24px",
    "&.Mui-selected": {
        backgroundColor: (theme: any) => theme.palette.primary.main,
        color: "#fff",
    },
    "&:hover": {
        backgroundColor: "#e0e0e0",
    },
}

const AllSoundsAvailable = ({ songList, selectedInstrument }: { songList: Song[] | undefined; selectedInstrument: Instrument }) => {
    const changeCurrentSong = useChangeCurrentSong()
    const [song, setSong] = useState<string>()
    const [hasUserInteracted, setHasUserInteracted] = useState<boolean>(false)
    const instrumentId = selectedInstrument.deviceId
    const instrumentType = selectedInstrument.type

    const connectedInstruments = useConnectedInstruments()

    const handleSoundAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
        setHasUserInteracted(true)
    }

    const handleValueAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
        setHasUserInteracted(true)
    }

    const handleChangeSound = ({ song }: { song: string }) => {
        console.log("vhangeing sounf ", song)
        if (!instrumentId) {
            console.error("Instrument ID is undefined")
            return
        }

        setSong(song)
        setHasUserInteracted(true)
    }

    useEffect(() => {
        if (hasUserInteracted && song && instrumentId) {
            console.log("IN USE EFFECT")
            changeCurrentSong({ songId: song, deviceId: instrumentId })
        }
    }, [song, hasUserInteracted, instrumentId])

    return (
        <Box>
            {songList && (
                <ToggleButtonGroup exclusive fullWidth onChange={handleSoundAlignment} aria-label="text alignment" sx={toggleButtonGroupStyling}>
                    {songList.map((song: Song) => (
                        <ToggleButton key={song.id} value={song.id} aria-label={song.title} onClick={() => handleChangeSound({ song: song.id })} sx={toggleButtonStyling}>
                            {formatString(song.title)}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            )}
        </Box>
    )
}

export default AllSoundsAvailable
